import React from "react"

import Layout from "../components/layout"
import PageBanner from "../components/pageBanner"
import Article from "../components/article"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <PageBanner title="About Us" />
    <Article title="We are Headmark Consulting">
      <p>
        Headmark Consulting is a specialised consulting company primarily
        focused on providing Subject Matter Experts with Information Domain
        expertise. Our team also provide more broad Defence and Industry
        experience and skill in Business and Operations Analysis.
      </p>
      <p>
        Our Information Domain expertise extends across Intelligence,
        Surveillance and Reconnaissance, Communications, Command and Control,
        Information Systems and Electronic Warfare in the Joint, Maritime, Land
        and Air domains.
      </p>
      <p>
        Our Consulting and Analyst expertise expands across all Domains and
        Warfare disciplines, as well Logistics and Engineering and Personnel.
      </p>
      <p>
        We provide expertise in Policy, Procedure and Governance, Capability
        Life Cycle, Integrated Logistics Support and experience in Capability
        Needs, Effects and Outputs generation and assessment.
      </p>
    </Article>
  </Layout>
)

export default AboutPage
